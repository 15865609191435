import React from "react";

const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dignissim turpis sit amet nisi fermentum, ut blandit nulla feugiat.",
    author: "— Jane Doe"
  },
  {
    quote:
      "Praesent commodo, justo in viverra tincidunt, nunc orci blandit velit, nec sollicitudin risus purus ut ligula.",
    author: "— John Smith"
  },
  {
    quote:
      "Curabitur imperdiet sapien at nulla efficitur, at iaculis massa sagittis. Integer vel elit vel justo viverra tincidunt.",
    author: "— Sarah Johnson"
  },
  {
    quote:
      "Donec ut orci nec sapien vulputate euismod. Morbi vel cursus ex. Etiam id urna eget justo ultrices feugiat.",
    author: "— David Lee"
  }
];

const TestimonialSection = () => (
  <section className="bg-gray-100 py-16 px-6">
    <div className="max-w-[1100px] mx-auto text-center">
      <h2 className="text-3xl font-semibold mb-12">What Others Are Saying</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {testimonials.map((t, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-2xl shadow-md hover:shadow-lg transition duration-300"
          >
            <p className="italic mb-4 text-gray-800">"{t.quote}"</p>
            <p className="font-bold text-gray-900">{t.author}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default TestimonialSection;
