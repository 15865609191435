import React from "react";

const BenefitsSection = () => (
  <section className="py-20 px-6 bg-white">
    <div className="max-w-[1440px] mx-auto text-center">
      <h2 className="text-4xl font-semibold mb-16">What You’ll Gain</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        <div className="p-8 border rounded-2xl shadow-lg hover:shadow-xl transition duration-300">
          <h3 className="text-2xl font-bold mb-4">Clarity</h3>
          <p className="text-base mb-3">
            Discover exactly which parts of your business are thriving and which ones need attention.
          </p>
          <p className="text-base">
            With crystal-clear insights, you’ll stop guessing and start making data-backed decisions with ease.
          </p>
        </div>

        <div className="p-8 border rounded-2xl shadow-lg hover:shadow-xl transition duration-300">
          <h3 className="text-2xl font-bold mb-4">Growth</h3>
          <p className="text-base mb-3">
            Unlock new opportunities you didn’t even know existed by analyzing patterns and performance.
          </p>
          <p className="text-base">
            Scale your business confidently with tailored strategies that are proven to work.
          </p>
        </div>

        <div className="p-8 border rounded-2xl shadow-lg hover:shadow-xl transition duration-300">
          <h3 className="text-2xl font-bold mb-4">Confidence</h3>
          <p className="text-base mb-3">
            Feel in control with a clear view of your numbers and trends at all times.
          </p>
          <p className="text-base">
            No more second-guessing — just smart, intentional actions that move the needle forward.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default BenefitsSection;
