import React from "react";

const CallToActionSection = () => (
  <section className="py-16 px-6 text-center bg-[#181349] text-white">
    <h2 className="text-3xl font-bold mb-4">Let’s Talk About What’s Next</h2>
    <p className="mb-10 max-w-xl mx-auto">
      You're one conversation away from clarity, direction, and meaningful growth.  
      This free call could be the turning point your business has been waiting for.
    </p>

    <form className="max-w-2xl mx-auto">
      <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-4">
        <input
          type="text"
          placeholder="Your Name"
          className="flex-1 px-4 py-3 rounded-md text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-[#BD9A35]"
        />
        <input
          type="email"
          placeholder="Your Email"
          className="flex-1 px-4 py-3 rounded-md text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-[#BD9A35]"
        />
      </div>
      <button
        type="submit"
        className="w-full md:w-auto bg-[#3C3CC8] hover:bg-red-700 text-white py-3 px-6 rounded-md transition duration-300"
      >
        Reserve your Spot
      </button>
    </form>
  </section>
);

export default CallToActionSection;
