// components/funnel/FunnelPage.jsx
import React, { useRef } from "react";
import HeroSection from "./HeroSection";
import BenefitsSection from "./BenefitsSection";
import TestimonialSection from "./TestimonialSection";
import CallToActionSection from "./CallToActionSection";

const FunnelPage = () => {
  const callToActionRef = useRef(null);

  const scrollToCallToAction = () => {
    if (callToActionRef.current) {
      callToActionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="w-full min-h-screen">
      <HeroSection scrollToStoreItem={scrollToCallToAction} />
      <BenefitsSection />
      <div ref={callToActionRef}>
        <CallToActionSection />
      </div>
      <TestimonialSection />
    </div>
  );
};

export default FunnelPage;
